import React from "react";
import { useTranslation } from "react-i18next";
import GetPosts from "../../components/posts";
import Header from "../../components/header"; // Importando o Header reutilizável
import "./index.css";

const Blog = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* Cabeçalho reutilizável */}
      <Header/>
      <div className="blog-main-container">
        {/* Conteúdo do Blog */}
        <h1 className="blog-title">{t("blog.title", "Blog")}</h1>
        <section className="blog-container">
          <GetPosts />
        </section>
      </div>
    </div>
  );
};

export default Blog;
