import './App.css';
import Home from './pages/home';
import Curriculum from './pages/curriculum';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import TextLanguage from './components/textLanguage';
import Blog from './pages/blog';
import PostPage from './pages/post';


function App() {
  return (<Router>
      <TextLanguage />
      <Switch>
        <Route path="/curriculum">
          <CurriculumPage />
        </Route>
        <Route path="/blog/:id">
          <PostsPage/>
        </Route>
        <Route path="/blog">
          <BlogPage/>
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
  </Router>)
}

function HomePage() {
  return <Home />;
}

function CurriculumPage() {
  return <Curriculum />;
}

function BlogPage() {
  return <Blog />;
}

function PostsPage() {
  return <PostPage />;
}

export default App;
