const translation = {
    translation: {
        under_construction: "Em Construção!",
        home: {
            title: "Home",
            description: "Bem-vindo ao meu site/blog! Este é um espaço onde compartilho conhecimentos e curiosidades sobre minhas paixões: programação, jogos e linguística!"
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Desenvolvedor de Software",
        about: "Sobre",
        blog: {
            title: "Blog",
            posts: [
              {
                id: 1,
                title: "Dungeon Command: Explorando o Padrão de Projeto Command",
                description: "Dungeon Command é um jogo de puzzle 3D desenvolvido como exemplo da aplicação do Padrão de Projeto Command, onde o jogador controla um personagem em um tabuleiro repleto de tiles, barreiras e coletáveis. O objetivo é coletar todos os itens espalhados pelo tabuleiro enquanto enfrenta bloqueios que exigem rotações estratégicas do tabuleiro para ajustar as barreiras e desbloquear novos caminhos.",
                image: "/images/post1.jpg",
                content: "Aqui está o conteúdo completo do Post 1.",
                unityPath: "/unity-build/CommandPattern/index.html",
              },
              {
                id: 2,
                title: "Título do Post 2",
                description: "Descrição breve do post 2.",
                image: "/images/post2.jpg",
                content: "Aqui está o conteúdo completo do Post 2.",
              },
              {
                id: 3,
                title: "Título do Post 3",
                description: "Descrição breve do post 3.",
                image: "/images/post3.jpg",
                content: "Aqui está o conteúdo completo do Post 3.",
              },
            ],
          },
        post: {
            notFound: "Post não encontrado.",
            error: "Ocorreu um erro ao carregar o post.",
            readMore: "Leia mais",
            goBack: "Voltar ao blog",
        },
        contact: "Contato",
        city_country: "Brasília, Brasil",
        profile: "Perfil",
        profile_description1: "Sou um desenvolvedor de jogos ítalo-brasileiro que possui um Certificado Profissional Unity e um Bacharelado em Ciência da Computação. Minha paixão por jogos, linguística e tecnologia impulsiona minha trajetória profissional.",
        profile_description2: "Entusiasta de Metodologias Ágeis, também sou poliglota e aprecio aprender novas linguagens naturais e de programação. Essa combinação de habilidades e interesses me posiciona de maneira única para contribuir com projetos inovadores que exijam proficiência técnica e resolução criativa de problemas.",
        professional_exp: "Experiência Profissional",
        jobs: [{
            job_position: "Engenheiro de Jogos Unity",
            company_name: "Fortis Games",
            location: "Brasília, Brasil",
            period: "Out 2023 - Atual",
            descriptions: [{
                0: "Engenheiro de Jogos responsável por recursos LiveOps para todos os novos jogos da Fortis.",
                1: "Como a Fortis é uma empresa nova, estamos criando nossos próprios SDKs/Ferramentas para oferecer suporte a todos os nossos jogos em desenvolvimento. Minha responsabilidade é implementar nos jogos o que nossa equipe de backend está desenvolvendo.",
                2: "Criei SDKs/Ferramentas para suportar jogos em desenvolvimento.",
                3: "Implementei recursos como sistema de Intersticiais, Loja In-game/IAP, Passe de Batalha, Recompensas Diárias e mais, utilizando Unity3D (C#)."
            }]
        },
        {
            job_position: "Engenheiro de Jogos Unity",
            company_name: "Azumo LLC",
            location: "Brasília, Brasil",
            period: "Ago 2023 - Nov 2023",
            descriptions: [{
                0: "Consultor contratado para a Zynga no jogo Game of Thrones: Legends.",
                1: "Implementei novos recursos de gameplay e interface do usuário, como melhorias em personagens e construções.",
                2: "Trabalhei em recursos como o FTUE do jogo e na aplicação de assets criados pela equipe de Tech Art.",
                3: "Colaborei lado a lado com Designers e Produtores da Zynga e participei de várias reuniões decisivas sobre o gameplay central do jogo.",
                4: "O jogo foi desenvolvido usando Unity3D (C#) e várias ferramentas e SDKs customizados da Zynga."
            }]
        },
        {
            job_position: "Desenvolvedor de Software Unity e C#",
            company_name: "Instituto ELDORADO",
            location: "Manaus, Brasil",
            period: "Fev 2023 - Out 2023",
            descriptions: [{
                0: "Desenvolvi projetos Unity3D e forneci suporte arquitetural.",
                1: "Reestruturei um jogo educacional embutido projetado para áreas remotas.",
                2: "Desenvolvi aplicativos desktop e móveis utilizando Unity3D, .NET Core e Kotlin."
            }]
        },
        {
            job_position: "Engenheiro de Jogos",
            company_name: "Wildlife Studios",
            location: "São Paulo, Brasil (Remoto)",
            period: "Jun 2022 - Nov 2022",
            descriptions: [{
                0: "Engenheiro de jogos responsável por dar suporte e desenvolver novos recursos para jogos live da Wildlife, tanto no cliente quanto no servidor.",
                1: "Trabalhei no 'Block Craft 3D', um jogo voxel desenvolvido em Cocos2D com mais de 1 milhão de usuários ativos diários.",
                2: "Também trabalhei no 'Suspects', um jogo de dedução social desenvolvido em Unity3D com mais de 250 mil usuários ativos diários.",
                3: "Utilizei frameworks Scrum e Kanban para implementar boas práticas de gerenciamento de equipe, contribuindo para um desenvolvimento mais ágil.",
                4: "As aplicações utilizam Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (backend), Redis e MongoDB."
            }]
        },
        {
            job_position: "Desenvolvedor de Software Unity",
            company_name: "Instituto Sidia de Ciência e Tecnologia",
            location: "Manaus, Brasil",
            period: "Abr 2021 - Jun 2022",
            descriptions: [{
                0: "Equipe de Desenvolvimento de Realidade Aumentada no Instituto Sidia. Responsável por desenvolver projetos confidenciais para vários clientes, incluindo a Samsung, ao criar aplicativos para óculos de realidade virtual e aumentada.",
                1: "Além de usar Unity Engine para AR/VR, utilizei C++ para atender às demandas do Samsung Watch3 (Tizen OS).",
                2: "Utilizei a metodologia Scrum para implementar boas práticas de gerenciamento de equipe, contribuindo para um desenvolvimento mais ágil.",
                3: "As aplicações utilizam Unity3D, C++, Mixed Reality Toolkit da Microsoft, SQL, Confluence e Jenkins, entre outras tecnologias."
            }]
        },
        {
            job_position: "Desenvolvedor Fullstack",
            company_name: "Grupo ICTS",
            location: "Brasília, Brasil",
            period: "Ago 2020 - Mar 2021",
            descriptions: [{
                0: "Equipe de Desenvolvimento da área de seguros de vida do SICOOB. Responsável pelo desenvolvimento de novos recursos para os diversos aplicativos relacionados ao seguro de vida.",
                1: "Utilizei o framework ágil Scrum para implementar boas práticas de gerenciamento de equipe, contribuindo para um desenvolvimento mais ágil.",
                2: "Os aplicativos de seguro de vida utilizam Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven e Jenkins como tecnologias e frameworks."
            }]
        },
        {
            job_position: "Desenvolvedor Fullstack",
            company_name: "Globalweb Outsourcing",
            location: "Brasília, Brasil",
            period: "Fev 2020 - Ago 2021",
            descriptions: [{
                0: "Equipe de Desenvolvimento na Caixa Econômica Federal, focado na solução web da loteria do Brasil. O site já tinha mais de dois milhões de pessoas registradas, correspondendo a 80 milhões de apostas.",
                1: "No front-end, implementei novos recursos no site, como notificações, reformulação do carrinho de compras e a adição de uma nova modalidade de apostas.",
                2: "Implementei também recursos para um aplicativo web voltado para gerentes, permitindo visualizar estatísticas e análises da aplicação de loteria.",
                3: "No back-end, trabalhei na integração do backend das loterias online com a API de pagamentos do Mercado Pago, um serviço similar ao PayPal.",
                4: "Participei da definição do esquema de banco de dados da aplicação (banco de dados DB2).",
                5: "Integrei a aplicação online às filas IBM MQ com um sistema legado de 30 anos desenvolvido em COBOL.",
                6: "A aplicação web de loteria utiliza Java, EJB, IBM MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame e OpenJPA como tecnologias."
            }]
        },
        {
            job_position: "Assistente de Pesquisa - Desenvolvedor Fullstack",
            company_name: "Instituto Brasileiro de Informação em Ciência e Tecnologia - IBICT",
            location: "Brasília, Brasil",
            period: "Set 2019 - Fev 2020",
            descriptions: [{
                0: "No Instituto Brasileiro de Informação em Ciência e Tecnologia, trabalhei analisando a arquitetura de informação do VISAO (Sistema de Observatório Aberto para Visualização de Informação), hospedado em visao.ibict.br.",
                1: "Meu principal objetivo foi propor um modelo de migração de dados para a infraestrutura da aplicação e comprovar sua viabilidade. Também identifiquei falhas de persistência de dados no banco de dados do VISAO.",
                2: "O projeto VISAO utilizava HTML, JavaScript, Angular, PHP e MySQL como ferramentas de desenvolvimento."
            }]
        }],
        education_title: "Educação",
        educations: [{
            course: "Pós-graduação em Desenvolvimento de Sistemas com Java",
            college: "Faculdade Unyleya",
            location: "Brasília, Brasil",
            period: "Mar. 2019 - Nov. 2019"
        },
        {
            course: "Bacharelado em Ciência da Computação",
            college: "Universidade de Brasília - UnB",
            location: "Brasília, Brasil",
            period: "Ago. 2014 - Dez. 2018"
        }],
        language_title: "Idiomas",
        portuguese: "Português (Nativo)", 
        english: "Inglês (Avançado)",
        italian: "Italiano (Avançado)",
        french: "Francês (Básico)",
    }
};

export default translation;
