import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./index.css"; // Importar os estilos específicos do Header

const Header = () => {
  const { t } = useTranslation();

  return (
    <header>
      {/* Links do site */}
      <div className="website-links">
        <div className="tooltip">
          <Link to="/" className="home">{t("home.title")}</Link>
        </div>
        <div className="tooltip">
          <Link to="/blog">{t("blog.title")}</Link>
        </div>
        <div className="tooltip">
          <span className="tooltiptextcover">{t("under_construction")}</span>
          <Link to="/contact">{t("contact")}</Link>
        </div>
      </div>

      {/* Fundo preto */}
      <div className="container--background" />
    </header>
  );
};

export default Header;
