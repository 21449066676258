import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/header";
import "./index.css";

const PostPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const posts = t("blog.posts", { returnObjects: true });
  const post = posts?.find((post) => post.id === parseInt(id, 10));

  if (!post) {
    return (
      <div className="post-page">
        <Header />
        <div className="post-page-error">
          <h1>{t("post.notFound", "Post não encontrado.")}</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="post-page">
      <Header />
      <section>
        <div className="post-container">
          <div className="post-header">
            <h1 className="post-title">{post.title}</h1>
          </div>
          <div className="unity-container">
            <iframe className="unity-iframe"
              src={post.unityPath} /* Usa o caminho dinâmico */
              title="Unity WebGL"
              width="auto"
              height="auto"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="post-content">
            <div>{post.content}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PostPage;
