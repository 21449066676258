const translation = {
    translation: {
        under_construction: "In costruzione!",
        home: {
            title: "Home",
            description: "Benvenuto nel mio sito/blog! Questo è uno spazio dove condivido conoscenze e curiosità sulle mie passioni: codice, giochi e linguistica!",
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Sviluppatore Software",
        about: "Chi Sono",
        blog: {
            title: "Blog",
            posts: [
              {
                id: 1,
                title: "Dungeon Command: Esplorando il Pattern di Progettazione Command",
                description: "Dungeon Command è un gioco rompicapo 3D sviluppato come esempio di applicazione del Pattern di Progettazione Command, in cui il giocatore controlla un personaggio su una plancia piena di tessere, barriere e oggetti da collezionare. L'obiettivo è raccogliere tutti gli oggetti sparsi sulla plancia affrontando ostacoli che richiedono rotazioni strategiche della plancia per regolare le barriere e sbloccare nuovi percorsi.",
                image: "/images/post1.jpg",
                content: "Ecco il contenuto completo del Post 1.",
                unityPath: "/unity-build/CommandPattern/index.html",
              },
              {
                id: 2,
                title: "Titolo del Post 2",
                description: "Breve descrizione del post 2.",
                image: "/images/post2.jpg",
                content: "Ecco il contenuto completo del Post 2.",
              },
              {
                id: 3,
                title: "Titolo del Post 3",
                description: "Breve descrizione del post 3.",
                image: "/images/post3.jpg",
                content: "Ecco il contenuto completo del Post 3.",
              },
            ],
          },
        post: {
            notFound: "Post non trovato.",
            error: "Si è verificato un errore durante il caricamento del post.",
            readMore: "Leggi di più",
            goBack: "Torna al blog",
        },          
        contact: "Contatto",
        city_country: "Manaus, Brasile",
        profile: "Profilo",
        profile_description1: "Sono uno sviluppatore di giochi italo-brasiliano con una certificazione Unity Professional e una laurea in Informatica. La mia passione per i giochi, la linguistica e la tecnologia guida il mio percorso professionale.",
        profile_description2: "Appassionato di metodologie Agile, sono anche un poliglotta che ama imparare nuove lingue naturali e di programmazione. Questo mix di competenze e interessi mi consente di contribuire a progetti innovativi che richiedono competenze tecniche e capacità di problem-solving creativo.",
        professional_exp: "Esperienza Professionale",
        jobs: [{
            job_position: "Ingegnere di Giochi Unity",
            company_name: "Fortis Games",
            location: "Brasilia, Brasile",
            period: "Ott 2023 - Presente",
            descriptions: [{
                0: "Ingegnere di giochi responsabile delle funzionalità LiveOps per tutti i nuovi giochi di Fortis.",
                1: "Essendo Fortis una compagnia relativamente nuova, stiamo creando i nostri SDK/strumenti per supportare tutti i nostri giochi in sviluppo, e sono responsabile di implementarli nei giochi sviluppati dal nostro team backend.",
                2: "Creati SDK/strumenti per supportare i giochi in sviluppo.",
                3: "Implementate funzionalità come sistema di interstitial, negozio in-game/IAP, Battlepass, premi giornalieri e altro utilizzando Unity3D (C#)."
            }]
        },
        {
            job_position: "Ingegnere di Giochi Unity",
            company_name: "Azumo LLC",
            location: "Brasilia, Brasile",
            period: "Ago 2023 - Nov 2023",
            descriptions: [{
                0: "Consulente per Zynga sul gioco Game of Thrones: Legends.",
                    1: "Implementate nuove funzionalità di gameplay e UI come aggiornamento di personaggi e edifici.",
                    2: "Lavorato su funzionalità come aggiornamento di personaggi e edifici, implementazione del FTUE del gioco e applicazione di asset creati dal team Tech Art.",
                    3: "Collaborato con designer e produttori di Zynga partecipando a molte decisioni sul gameplay principale del gioco.",
                    4: "Il gioco è stato sviluppato utilizzando Unity3D (C#) e molti SDK/strumenti personalizzati di Zynga."
            }]
        },
        {
            job_position: "Sviluppatore Software Unity & C#",
            company_name: "ELDORADO Institute",
            location: "Manaus, Brasile",
            period: "Feb 2023 - Ott 2023",
            descriptions: [{
                0: "Ho lavorato al refactoring di un gioco educativo integrato a Unity3D, originariamente sviluppato in JavaScript. Il gioco è progettato per funzionare su hardware con limitazioni di memoria e dimensioni, consentendo di portarlo in luoghi difficili da raggiungere (come villaggi nella foresta amazzonica).",
                1: "Oltre a Unity3D, sono stato responsabile dello sviluppo e del supporto di applicazioni desktop in .NET Core e mobili in Kotlin.",
                2: "Ho lavorato allo sviluppo di un software per desktop e dispositivi mobili per la lettura e scrittura di dati su dispositivi di misurazione elettrica della Nansen, uno dei produttori di contatori più tradizionali al mondo.",
                3: "Le applicazioni utilizzano Unity3D (C#), .NET Core (WPF), Kotlin, tra altre tecnologie."
            }]
        },
            {
            job_position: "Ingegnere di Gioco",
            company_name: "Wildlife Studios",
            location: "Sao Paulo, Brasile (Remoto)",
            period: "Jun 2022 - Nov 2022",
            descriptions: [{
                0: "Ingegnere di gioco responsabile del supporto e dello sviluppo di nuove funzionalità per i giochi Wildlife live sia sul lato client che su quello server",
                1: "Ho lavorato in Cocos2D 'Block Craft 3D', un gioco voxel da +1,0 milioni di utenti attivi al giorno che consiste nel costruire qualsiasi cosa il giocatore desideri",
                2: "Ho lavorato anche in Unity3D 'Suspects', un gioco di deduzione sociale da +250k utenti attivi al giorno con chat vocale integrata e un sistema di progressione adeguato",
                3: "Utilizzo dei framework Scrum e Kanban per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile",
                4: "Le applicazioni utilizzano come tecnologie Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis e MongoDB",
            }],
        },
        {
            job_position: "Sviluppatore Unity",
            company_name: "Sidia Istituto di Scienza e Tecnologia",
            location: "Manaus, Brasile",
            period: "Apr 2021 - Jun 2022",
            descriptions: [{
                0: "Team di sviluppo presso il team di realtà aumentata del Sidia Institute of Science and Technology. Responsabile dello sviluppo di alcuni progetti riservati per vari clienti, come Samsung, sviluppando applicazioni per occhiali di realtà virtuale e realtà aumentata",
                1: "Oltre a Unity Engine per AR/VR, è stato utilizzato anche C++ per soddisfare le richieste di Samsung Watch3 (Tizen OS)",
                2: "Uso del framework Scrum per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile",
                3: "Le applicazioni utilizzano, tra le altre tecnologie riservate, Unity3D, C++, Microsoft Mixed Reality Toolkit, SQL, Confluence e Jenkins come tecnologie",
            }],
        },
        {
            job_position: "Sviluppatore software Fullstack",
            company_name: "Grupo ICTS",
            location: "Brasilia, Brasile",
            period: "Ago 2020 - Mar 2021",
            descriptions: [{
                0: "Team Dev presso il team assicurazioni vita della SICOOB. È responsabile dello sviluppo di nuove funzionalità per le varie applicazioni relative alle assicurazioni sulla vita.",
                1: "Utilizzazione del framework di sviluppo agile Scrum per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile.",
                2: "L'applicazione di assicurazione sulla vita utilizza come tecnologie e framework Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven e Jenkins.",
            }],
        },
        {
            job_position: "Sviluppatore software Fullstack",
            company_name: "Globalweb Outsourcing",
            location: "Brasilia, Brasile",
            period: "Feb 2020 - Ago 2021",
            descriptions: [{
                0: "Dev Team della Caixa Econômica Federal per la soluzione web della lotteria brasiliana. Il sito web aveva circa un anno di vita e aveva già più di due milioni di persone registrate, il che corrisponde a 80 milioni di scommesse alla lotteria",
                1: "Nel frontend, ho lavorato all'implementazione di nuove funzionalità del sito, come le notifiche, il refactor del carrello e l'aggiunta di nuove modalità di scommessa",
                2: "Ho lavorato anche all'implementazione di funzionalità per un'applicazione web per i manager, in modo che potessero vedere statistiche e analisi dall'applicazione della lotteria",
                3: "Nel backend, ho lavorato all'integrazione del backend delle lotterie online con le API di pagamento di Mercado Pago, un servizio simile a PayPal",
                4: "Ho lavorato alla definizione dello schema del database dell'applicazione (database DB2)",
                5: "Ho lavorato anche all'integrazione dell'applicazione online attraverso le code MQ di IBM con un sistema di 30 anni fa sviluppato in COBOL",
                6: "L'applicazione web della lotteria utilizza come tecnologie Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame e OpenJPA",
            }],
        },
        {
            job_position: "Assistente di Ricerca - Sviluppatore Fullstack",
            company_name: "Istituto Brasiliano di Informazione in Scienza e Tecnologia - IBICT",
            location: "Brasilia, Brasile",
            period: "Set 2019 - Feb 2020",
            descriptions: [{
                0: "Presso l'Istituto brasiliano di scienza e tecnologia dell'informazione ho lavorato analizzando l'architettura dell'informazione di VISAO (Open Observatory System for Information Visualization), ospitato su visao.ibict.br.",
                1: "Il mio obiettivo principale era proporre un modello di migrazione dei dati per l'infrastruttura dell'applicazione e dimostrarne la fattibilità. Mi sono anche occupato dei problemi di persistenza dei dati nel database VISAO.",
                2: "Il progetto VISAO ha utilizzato HTML, JavaScript, Angular, PHP e MySQL come strumenti di sviluppo.",
            }],
        }],
        education_title: "Education",
        educations: [{
            course: "Post-laurea in Sviluppo di Sistemi Con Java",
            college: "Faculdade Unyleya",
            location: "Brasilia, Brasile",
            period: "Mar 2019 - Nov 2019"
        },
        {
            course: "Laurea Triennale in Informatica",
            college: "Università di Brasilia - UnB",
            location: "Brasilia, Brasile",
            period: "Ago 2014 - Dic 2018"
        }],
        language_title: "Lingue",
        portuguese: "Portoghese (Nativo)", 
        english: "Inglese (Avanzato)",
        italian: "Italiano (Avanzato)",
        french: "Francese (Basico)",
    }
};

export default translation;