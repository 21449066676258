const translation = {
    translation: {
        under_construction: "Under Construction!",
        home: {
            title: "Home",
            description: "Welcome to my website/blog! This is a place where I share knowledge and curiosities about my passions: code, games and linguistics!",
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Software Developer",
        about: "About",
        blog: {
            title: "Blog",
            posts: [
              {
                id: 1,
                title: "Dungeon Command: Exploring the Command Design Pattern",
                description: "Dungeon Command is a 3D puzzle game developed as an example of the application of the Command Design Pattern, where the player controls a character on a board filled with tiles, barriers, and collectibles. The goal is to collect all the items scattered across the board while facing obstacles that require strategic board rotations to adjust the barriers and unlock new paths.",
                image: "/images/post1.jpg",
                content: "Here is the full content of Post 1.",
                unityPath: "/unity-build/CommandPattern/index.html",
              },
              {
                id: 2,
                title: "Post Title 2",
                description: "Brief description of post 2.",
                image: "/images/post2.jpg",
                content: "Here is the full content of Post 2.",
              },
              {
                id: 3,
                title: "Post Title 3",
                description: "Brief description of post 3.",
                image: "/images/post3.jpg",
                content: "Here is the full content of Post 3.",
              },
            ],
          },
        post: {
            notFound: "Post not found.",
            error: "An error occurred while loading the post.",
            readMore: "Read more",
            goBack: "Back to blog",
        },          
        contact: "Contact",
        city_country: "Brasilia, Brazil",
        profile: "Profile",
        profile_description1: "I am a Brazilian-Italian game developer who holds a Unity Professional Certificate and a Bachelor's Degree in Computer Science. My passion for gaming, linguistics, and technology drives my professional journey.",
        profile_description2: "An enthusiast of Agile Methodologies, I am also a polyglot who enjoys learning new natural and programming languages. This blend of skills and interests uniquely positions me to contribute to innovative projects that require technical proficiency and creative problem-solving.",
        professional_exp: "Professional Experience",
        jobs: [{
            job_position: "Unity Game Engineer",
            company_name: "Fortis Games",
            location: "Brasilia, Brazil",
            period: "Oct 2023 - Current",
            descriptions: [{
                0: "Game Engineer responsible for LiveOps features for all new games from Fortis.",
                1: "Since Fortis is aquite new Company, we're creating our own SDKs/Tools to provide support to all of our in development games, and my responsible is to implement into the games what our backend team is developing.",
                2: "Created SDKs/Tools to support in-development games.",
                3: "Implemented features such as Interstitials system, In-game/IAP Store, Battlepass, Daily Rewards, and more using Unity3D (C#)."
            }]
        },
        {
            job_position: "Unity Game Engineer",
            company_name: "Azumo LLC",
            location: "Brasilia, Brazil",
            period: "Aug 2023 - Nov 2023",
            descriptions: [{
                0: "Contract Vendor Consultant for Zynga on Game of Thrones: Legends.",
                    1: "Implemented new gameplay and UI features such as upgrading characters and buildings.",
                    2: "I've worked on features such as upgrading characters and buildings, implementing the game FTUE and applying assets made by the Tech Art team.",
                    3: "I've also worked side by side with Designers and Producers from Zynga and attending many decision meeting for the game's core gameplay.",
                    4: "The game was built using Unity3D (C#) as well as many other Zynga's custom SDK/Tools."
            }]
        },
        {
            job_position: "Unity & C# Software Developer",
            company_name: "ELDORADO Institute",
            location: "Manaus, Brazil",
            period: "Feb 2023 - Oct 2023",
            descriptions: [{
                0: "Trabalhei no refatoramento de um jogo educacional integrado ao Unity3D, originalmente desenvolvido em JavaScript. O jogo foi projetado para rodar em hardware com limitações de memória e tamanho, permitindo que ele fosse levado para locais de difícil acesso (como cidades no meio da floresta amazônica).",
                1: "Além do Unity3D, também sou responsável pelo desenvolvimento e suporte de aplicativos desktop em .NET Core e móveis em Kotlin.",
                2: "Trabalhei no desenvolvimento de um software para desktop e dispositivos móveis para leitura e escrita de dados em dispositivos de medição de eletricidade da Nansen, uma das fabricantes de medidores mais tradicionais do mundo.",
                3: "Os aplicativos utilizam Unity3D (C#), .NET Core (WPF), Kotlin, entre outras tecnologias."
            }]
        },
        {
        job_position: "Game Engineer",
        company_name: "Wildlife Studios",
        location: "Sao Paulo, Brazil (Remote)",
        period: "Jun 2022 - Nov 2022",
        descriptions: [{
            0: "Game engineer responsible for supporting and developing new features for Wildlife live games in both client and server sides.",
            1: "I've worked in Cocos2D 'Block Craft 3D', a +1.0M daily active users voxel game about building whatever the player wants.",
            2: "I've worked also in Unity3D 'Suspects', a +250k daily active users Social Deduction game with integrated voice chat and proper progression system.",
            3: "Use of Scrum and Kanban frameworks in order to implement good team management practices that contribute to more agile development.",
            4: "The applications use Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis and MongoDB as technologies.",
        }],
        },
        {
            job_position: "Unity Software Developer",
            company_name: "Sidia Institute of Science and Technology",
            location: "Manaus, Brazil",
            period: "Apr 2021 - Jun 2022",
            descriptions: [{
                0: "Dev Team at Sidia Institute of Science and Technology's Augmented Reality team. Responsible for developing some confidential projects for various clients such Samsung by developing application for virtual reality glasses and augmented reality.",
                1: "Beside Unity Engine for AR/VR, C++ was also used in order to fulfill demands for Samsung Watch3 (Tizen OS).",
                2: "Use of Scrum framework in order to implement good team management practices that contribute to more agile development.",
                3: "The applications use, among other confidential technologies, Unity3D, C++, Microsoft's Mixed Reality Toolkit, SQL, Confluence and Jenkins as technologies.",
            }],
        },
        {
            job_position: "Fullstack Software Developer",
            company_name: "Grupo ICTS",
            location: "Brasilia, Brazil",
            period: "Aug 2020 - Mar 2021",
            descriptions: [{
                0: "Dev Team at SICOOB's life insurance team. Responsible for developing new features for the various applications involving human life insurance.",
                1: "Use of Scrum agile development framework in order to implement good team management practices that contribute to more agile development.",
                2: "The life insurance application uses Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven and Jenkins as technologies and frameworks.",
            }],
        },
        {
            job_position: "Fullstack Software Developer",
            company_name: "Globalweb Outsourcing",
            location: "Brasilia, Brazil",
            period: "Feb 2020 - Aug 2021",
            descriptions: [{
                0: "Dev Team at Caixa Econômica Federal on the web solution of Brazil's lottery. The website was about one year old at the moment and have already had more than two million people registered, which it corresponds to 80 million lottery bets.",
                1: "In the frontend, I've worked implementing new features to the website such notifications, shopping cart refactor and the addition to new bet modality.",
                2: "I've worked also implementing features for a web application for manager, so they could see statistics and analytics from the lotteria application.",
                3: "In the backend, I've Worked in the integration of the online lotteries backend with the payment API from Mercado Pago, a service similar to PayPal.",
                4: "I've Worked in definition of the application's database schema (DB2 database).",
                5: "I've Worked also in the integration of the online application through IBM's MQ queues with an already 30 years old system developed in COBOL",
                6: "The lottery web application uses Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame and OpenJPA as technologies.",
            }],
        },
        {
            job_position: "Research Asssistant - Fullstack Developer",
            company_name: "Brazilian Institute of Information in Science and Technology - IBICT",
            location: "Brasilia, Brazil",
            period: "Sep 2019 - Feb 2020",
            descriptions: [{
                0: "At the Brazilian Institute of Information Science and Technology I worked by analysing the information architecture of VISAO (Open Observatory System for Information Visualization), hosted at visao.ibict.br.",
                1: "My main goal there was to propose a data migration model for the application infrastructure and to prove it's viability. I also looked up for data persistence failures in the VISAO database.",
                2: "The VISAO project used HTML, JavaScript, Angular, PHP and MySQL as development tools",
            }],
        }],
        education_title: "Education",
        educations: [{
            course: "Postgraduate in Systems Development with Java",
            college: "Faculdade Unyleya",
            location: "Brasilia, Brazil",
            period: "Mar. 2019 - Nov. 2019"
        },
        {
            course: "Bachelor's Degree in Computer Science",
            college: "Universidade de Brasília - UnB",
            location: "Brasilia, Brazil",
            period: "Aug. 2014 - Dec. 2018"
        }],
        language_title: "Languages",
        portuguese: "Portuguese (Native)", 
        english: "English (Advanced)",
        italian: "Italian (Advanced)",
        french: "French (Basic)",
    }
};

export default translation;