import profilePic from "../../assets/images/profile.jpg";
import "./index.css";
import twitterIcon from "../../assets/icons/twitter.png";
import linkedinIcon from "../../assets/icons/linkedin.png";
import githubIcon from "../../assets/icons/github.png";
import mailIcon from "../../assets/icons/mail.png";
import { useTranslation } from 'react-i18next';
import GetJobs from "../../components/jobs";
import GetEducation from "../../components/education";
import GetLanguages from "../../components/language";

const Curriculum = () => {

    const { t } = useTranslation();

    return (
        <div className="curriculum-container">
            <div className="curriculum-title">
                <div className="portrait--area">
                    <div className="circular--portrait">
                        <div className="foto">
                            <img src={profilePic} alt="foto-perfil" />
                        </div>
                    </div>
                </div>
                <div className="header">
                    <h1>Henrique Torres de Almeida Rodrigues</h1>
                    <div id="subtitle">
                        <p>{t("job_description")}</p>
                        <span>|</span>
                        <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/ogBowzYpejnxWQhu8">{t("city_country")}</a>
                    </div>
                </div>
            </div>

            <div className="neck--line" />

            <div className="curriculum-content--container">
                {/* LEFT COLUMN */}
                <div className="column" id="left-column">
                    <div className="content--left-div">
                        <div className="external-links">
                            <h2>{t("contact")}</h2>
                            <div className="external-links-item">
                                <img src={mailIcon} alt="email" /><a target="_blank" rel="noreferrer" href="http://www.henritar.dev">henriquetorres92@gmail.com</a>
                            </div>
                            <div className="external-links-item">
                                <img src={twitterIcon} alt="twitter" /><a target="_blank" rel="noreferrer" href="http://twitter.com/henritar">henritar</a>
                            </div>
                            <div className="external-links-item">
                                <img src={linkedinIcon} alt="linkedin" /><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/henritar/">henritar</a>
                            </div>
                            <div className="external-links-item">
                                <img src={githubIcon} alt="github" /><a target="_blank" rel="noreferrer" href="https://www.github.com/henritar/">henritar</a>
                            </div>
                        </div>

                        <div className="content--left-divisor" />

                        <h2>{t("education_title")}</h2>
                        <GetEducation />

                        <div className="content--left-divisor" />

                        <h2>{t("language_title")}</h2>
                        <GetLanguages />

                    </div>

                </div>

                {/* BODY DIVISOR */}
                <div className="body--divisor" />

                {/* RIGHT COLUMN */}
                <div className="column" id="right-column">
                    <div className="content--right-div">
                        <h2>{t("profile")}</h2>
                        <p>{t("profile_description1")}</p>
                        <p>{t("profile_description2")}</p>

                        <div className="content--right-divisor" />
                        <h2>{t("professional_exp")}</h2>
                        <GetJobs />

                    </div>


                </div>

            </div>

            {/* <div className="footer">
                <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </div> */}
        </div>
    );
}

export default Curriculum;