import "./index.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function GetPosts() {
  const { t } = useTranslation();
  const myPosts = t("blog.posts", { returnObjects: true }); // Recupera o array de posts

  // Valida se myPosts é um array
  if (!Array.isArray(myPosts)) {
    console.error("Erro: os posts não foram carregados corretamente.");
    return <div className="blog-error">{t("post.error", "Erro ao carregar os posts.")}</div>;
  }

  return (
    <div className="blog-list">
      {myPosts.map((post) => (
        <div key={post.id} className="blog-item">
          <img src={post.image} alt={post.title} className="blog-image" />
          <h2>{post.title}</h2>
          <p>{post.description}</p>
          {/* Substitui <a> por <Link> para navegação interna */}
          <Link to={`/blog/${post.id}`} className="blog-read-more">
            {t("post.readMore", "Leia mais")}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default GetPosts;
